<div class="container-fluid visit-store" *ngIf="config.title !== 'Aiema' && config.title !== 'AvonSeals'">
    <ul>
        <li>
            <h3 >Visit our latest products</h3>
        </li>
        <li>
            <a class="btn btn-secondary" (click)="getLatestProducts()" >Know More &nbsp; <i
                    class="fa-angle-right fa"></i></a>
        </li>
    </ul>
</div>
<div class="container-fluid visit-store" *ngIf="config.title === 'Aiema'" style="background-image: linear-gradient(to right top, #051937, #004d7a, #008793, #37b4b0, #33e4de);font-family: 'proxima-regular','sans-serif'!important;">
    <ul style="margin-bottom:0px !important">
        <li>
            <h3>Explore AIEMA</h3>
        </li>
        <li>
            <a class="btn btn-secondary" (click)="getLatestProducts()" >Know More &nbsp; <i
                    class="fa-angle-right fa"></i></a>
        </li>
    </ul>
</div>
<footer class="container-fluid footer" *ngIf="config.title !== 'Aiema' && config.title !== 'BombayHardware' && config.title !== 'AvonSeals' && config.title !== 'Beaubelle'">
    <div class="container">
        <div class="footer-links footer-row-one">
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;">ONLINE SHOPPING</h4>
                <ul >
                    <li *ngFor="let online of onLineShopping;" (click)="navigateToProduct(online.link)"><a style="color: #375673 !important;font-weight:450"
                            class="custom-point">{{online.name}}</a></li>
                </ul>
            </div>
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;">QUICK LINKS</h4>
                <ul>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/">Home</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="about-us">About Us</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="contact-us">Contact Us</a></li>
                    <!-- <li (click)="getBrochure()"><a >Brochure</a></li> -->
                </ul>
            </div>
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;">USEFUL LINKS</h4>
                <ul>
                    <!-- <li><a style="color: #375673 !important;font-weight:450" routerLink="about-us">Contact Us</a></li> -->
                    <!-- <li><a style="color: #375673 !important;font-weight:450" routerLink="/home">FAQ</a></li> -->
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/terms-and-condition">Terms & Conditions</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/privacy-policy">Privacy Policy</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/cancellation-policy">Cancellation Policy</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/shipping-and-delivery">Shipping & Delivery</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/return-policy">Return & Exchanges</a></li>
                </ul>
            </div>
            <div class="footer-col contact-section address">
                <h4 class="sm-bold" style="color: #212529;">CONTACT US</h4>
                <p style="color: #375673 !important;"><i class="fa-map-marker fa"></i>
                    Green Cotton Company<br>
                    No. 8, BM – 441,<br>
                    1st Block HRBR Layout,<br>
                    Bangalore – 560043,<br>
                    Karnataka.
                </p>
                <p><i class="fa-phone  fa"></i>
                    <a style="color: #375673 !important;font-weight:450">+91 8884500900</a>
                </p>
                <p><i class="fa-globe  fa"></i>
                    <a style="color: #375673 !important;font-weight:450" href="enquiry@earthyworthy.in" target="_blank">enquiry@earthyworthy.in</a>
                </p>
            </div>
        </div>
    </div>
</footer>
<footer class="container-fluid footer" *ngIf="config.title === 'Beaubelle'">
    <div class="container">
        <div class="footer-links footer-row-one">
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;">ONLINE SHOPPING</h4>
                <ul >
                    <li *ngFor="let online of onLineShopping;" (click)="navigateToCategory(online.name)"><a style="color: #375673 !important;font-weight:450"
                            class="custom-point">{{online.name}}</a></li>
                </ul>
            </div>
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;">QUICK LINKS</h4>
                <ul>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/">Home</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="about-us">About Us</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="contact-us">Contact Us</a></li>
                    <!-- <li (click)="getBrochure()"><a >Brochure</a></li> -->
                </ul>
            </div>
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;">USEFUL LINKS</h4>
                <ul>
                    <!-- <li><a style="color: #375673 !important;font-weight:450" routerLink="about-us">Contact Us</a></li> -->
                    <!-- <li><a style="color: #375673 !important;font-weight:450" routerLink="/home">FAQ</a></li> -->
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/terms-and-condition">Terms & Conditions</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/privacy-policy">Privacy Policy</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/cancellation-policy">Cancellation Policy</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/shipping-and-delivery">Shipping & Delivery</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/return-policy">Return & Exchanges</a></li>
                </ul>
            </div>
            <div class="footer-col contact-section address">
                <h4 class="sm-bold" style="color: #212529;">CONTACT US</h4>
                <p style="color: #375673 !important;"><i class="fa-map-marker fa"></i>
                    Beaubelle Products & Care Private Limited<br>
                    53/76, Venkatachalam Street,<br>
                    Royapuram,<br>
                    Chennai 600013,<br>
                    Tamil Nadu.
                </p>
                <p><i class="fa-phone  fa"></i>
                    <a style="color: #375673 !important;font-weight:450">+91 9444667560 </a>
                </p>
                <p><i class="fa-globe  fa"></i>
                    <a style="color: #375673 !important;font-weight:450" href="amirtha@beaubelle.in" target="_blank">amirtha@beaubelle.in</a>
                </p>
            </div>
        </div>
    </div>
</footer>
<footer class="container-fluid footer" *ngIf="config.title === 'Aiema'">
    <div class="container" style="font-family: 'proxima-regular','sans-serif'!important;">
        <div class="footer-links footer-row-one" >
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529; font-weight: 650;">ONLINE ENQUIRY</h4>
                <ul >
                    <li *ngFor="let online of onLineShopping;" (click)="navigateToProduct(online.link)"><a style="color: #375673 !important;font-weight:450"
                            class="custom-point">{{online.name}}</a></li>
                </ul>
            </div>
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;  font-weight: 650;">QUICK LINKS</h4>
                <ul>
                    <li><a style="color: #375673 !important;font-weight:450" (click)='scrollFunction()' routerLink="/">Home</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" (click)='scrollFunction()' routerLink="about-us">About Us</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" (click)='scrollFunction()' routerLink="contact-us">Contact Us</a></li>
                    <!-- <li (click)="getBrochure()"><a >Brochure</a></li> -->
                </ul>
            </div>
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;  font-weight: 650;">USEFUL LINKS</h4>
                <ul>
                    <li><a style="color: #375673 !important;font-weight:450" (click)='scrollFunction()' routerLink="/terms-and-condition">Terms & Conditions</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" (click)='scrollFunction()' routerLink="/privacy-policy">Privacy Policy</a></li>
                </ul>
            </div>
            <div class="footer-col contact-section address">
                <h4 class="sm-bold" style="color: #212529;  font-weight: 650;">CONTACT US</h4>
                <p *ngIf="config.title === 'Aiema'" style="color: #375673 !important;"><i class="fa-map-marker fa"></i>
                    Ambattur Industrial Estate Manufacturers’ Association,<br>
                    ATC Road,<br>
                    Ambattur Industrial Estate,<br>
                    Chennai – 600058,<br>
                    Tamilnadu.
                </p>
                <p><i class="fa-phone  fa"></i>
                    <a style="color: #375673 !important;font-weight:450">+91 8939628603</a>
                </p>
                <p><i class="fa-envelope  fa"></i>
                    <a style="color: #375673 !important;font-weight:450" href="mailto: mail@aiema.net">mail@aiema.net</a>
                </p>
            </div>
        </div>
    </div>
</footer>

<footer class="container-fluid footer" *ngIf="config.title === 'BombayHardware'">
    <div class="container" style="font-family: 'proxima-regular','sans-serif'!important;">
        <div class="footer-links footer-row-one">
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;">ONLINE ENQUIRY</h4>
                <ul >
                    <li *ngFor="let online of onLineShopping;" (click)="navigateToProduct(online.link)"><a style="color: #375673 !important;font-weight:450"
                            class="custom-point">{{online.name}}</a></li>
                </ul>
            </div>
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;">QUICK LINKS</h4>
                <ul>
                    <li><a style="color: #375673 !important;font-weight:450" (click)='scrollFunction()' routerLink="/">Home</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" (click)='scrollFunction()' routerLink="about-us">About Us</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" (click)='scrollFunction()' routerLink="contact-us">Contact Us</a></li>
                    <!-- <li (click)="getBrochure()"><a >Brochure</a></li> -->
                </ul>
            </div>
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;">USEFUL LINKS</h4>
                <ul>
                    <li><a style="color: #375673 !important;font-weight:450" (click)='scrollFunction()' routerLink="/terms-and-condition">Terms & Conditions</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" (click)='scrollFunction()' routerLink="/privacy-policy">Privacy Policy</a></li>
                </ul>
            </div>
            <div class="footer-col contact-section address">
                <h4 class="sm-bold" style="color: #212529;">CONTACT US</h4>
                <p style="color: #375673 !important;"><i class="fa-map-marker fa"></i>
                    Bombay Hardware Private Limited<br>
                    No. 32, Sembudoss Street, 1st Floor, Parrys,<br>
                    Corner Estate, George Town, <br>
                    Chennai - 600001,<br>
                    Tamil Nadu, India
                </p>
                <p><i class="fa-phone  fa"></i>
                    <a style="color: #375673 !important;font-weight:450">08048976462</a>
                </p>
                <p><i class="fa-envelope  fa"></i>
                    <a style="color: #375673 !important;font-weight:450" href=""></a>
                </p>
            </div>
        </div>
    </div>
</footer>

<footer class="container-fluid footer" style="background-color: #020a12;">
    <div class="container">
        <div class="footer-links footer-row-one">
            
            <div class="footer-col">
                
                <ul>
                    <li><p *ngIf = "config.title == 'AvonSeals'"><strong style="color: #ff1f1f !important;font-size:45px">AVONSEALS</strong></p></li>
                    <li><p style="color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;" *ngIf = "config.title == 'AvonSeals'">Viverra suspendisse potenti nullam ac. Sed lectus vestibulum mattis ullamcorper.</p></li><br>
                    <!-- <li><input placeholder="Enter Your Email" class="textareafull" name="inputValue"> </li><br> -->
                    <div class="buttonIn" style="display:flex;width:300px">
                        <input type="text" id="enter" placeholder="Enter Your Email">
                        <button id="subscribe" style="color: white; background-color: #ff1f1f;height:55px;width:160px !important">Subscribe</button>
                    </div><br>
                    <li><p style="color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;" *ngIf = "config.title == 'AvonSeals'">We do not spam. We send offers instead.</p></li>
                    <!-- <li (click)="getBrochure()"><a >Brochure</a></li> -->
                </ul>
            </div>
            <div class="footer-col" *ngIf = "config.title == 'AvonSeals'">
            <ul style="list-style-type:disc">
                <li style="list-style-type: disc;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;">Home</li>
                <li style="list-style-type: disc;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;">About</li>
                <li style="list-style-type: disc;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;">Blog</li>
                <li style="list-style-type: disc;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;">Faq</li>
                <li style="list-style-type: disc;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;">Contact</li>
                <li style="list-style-type: disc;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;">Wishlist</li>
            </ul>
        </div>
        <div class="footer-col" *ngIf = "config.title == 'AvonSeals'">
        <ul>
            <li style="list-style-type: disc;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;">Compare</li>
            <li style="list-style-type: disc;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;">Orders</li>
            <li style="list-style-type: disc;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;">Order Status</li>
            <li style="list-style-type: disc;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;">Deliveries</li>
            <li style="list-style-type: disc;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;">Cancellation</li>
            <li style="list-style-type: disc;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;">Store Location</li>
        </ul>
    </div>
    <div class="footer-col" *ngIf = "config.title == 'AvonSeals'">
    <ul>
        <li style="list-style-type: disc;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;">Career</li>
        <li style="list-style-type: disc;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;">Help Center</li>
        <li style="list-style-type: disc;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;">Return return-policy</li>
        <li style="list-style-type: disc;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;">Privacy Policy</li>
        <li style="list-style-type: disc;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;">Advanced Search</li>
        <li style="list-style-type: disc;color: white; font-size: 15px;font-family: 'proxima-regular','sans-serif'!important;">Shipping Information</li>
    </ul>
</div>
            <div class="footer-col" *ngIf="config.title !== 'AvonSeals'">
                <h4 class="sm-bold" style="color: #212529;" >USEFUL LINKS</h4>
                <ul>
                    
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="contact-us" *ngIf = "config.title != 'ShubhCards' && config.title != 'Deera' && config.title != 'AvonSeals' && config.title != 'BestGifts' && config.title != 'Carience'">Contact Us</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/FAQs" *ngIf = "config.title != 'Deera' && config.title != 'AvonSeals' && config.title != 'BestGifts' && config.title != 'Carience'">FAQ</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/terms-and-condition" *ngIf = "config.title != 'AvonSeals'">T&amp;C</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/privacy-policy" *ngIf = "config.title != 'AvonSeals'">Privacy Policy</a></li>
                    <li *ngIf = "config.title != 'Carience'"><a style="color: #375673 !important;font-weight:450" routerLink="/cancellation-policy" *ngIf = "config.title != 'AvonSeals'">Cancellation Policy</a></li>
                    <li *ngIf = "config.title != 'Carience'"><a style="color: #375673 !important;font-weight:450" routerLink="/shipping-and-delivery" *ngIf = "config.title != 'AvonSeals'">Shipping & Delivery</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/return-policy" *ngIf = "config.title == 'ShubhCards' || config.title == 'Deera' ||config.title == 'BestGifts'">Return & Exchanges</a></li>
                </ul>
            </div>
            
            <div class="footer-col contact-section address" *ngIf = "config.title == 'LuckMe'">
                <h4 class="sm-bold" style="color: #212529;">CONTACT US</h4>
                <p style="color: #375673 !important;"><i class="fa-map-marker fa"></i>
                    B S M Complex, Revolution Av,<br>
                    Victoria, Mahe
                    <br>
                    
                </p>
                <p><i class="fa-phone  fa"></i>
                    <a style="color: #375673 !important;font-weight:450">+248 251 3732</a>
                </p>
                <p><i class="fa-globe  fa"></i>
                    <a style="color: #375673 !important;font-weight:450" href="sales@supersoma.sc" target="_blank">sales@supersoma.sc</a>
                </p>
            </div>
            <div class="footer-col contact-section address" *ngIf = "config.title == 'ShubhCards'" >
                <h4 class="sm-bold" style="color: #212529;">CONTACT US</h4>
                <p style="color: #375673 !important;"><i class="fa-map-marker fa"></i>
                    New #16,Subramaniam Street, Abhiramapuram,Chennai,<br>
                    Tamil Nadu 600018
                    <br>
                    
                </p>
                <p><i class="fa-phone  fa"></i>
                    <a style="color: #375673 !important;font-weight:450">+91-44-42327190</a>
                </p>
                <p><i class="fa fa-mobile"></i>
                    <a style="color: #375673 !important;font-weight:450">+91-98400 49389</a>
                </p>
                <p><i class="fa-globe  fa"></i>
                    <a style="color: #375673 !important;font-weight:450"  target="_blank">shubhcards@gmail.com</a>
                </p>
            </div>
            <div class="footer-col contact-section address" *ngIf = "config.title == 'Deera'" >
                <h4 class="sm-bold" style="color: #212529;">CONTACT US</h4>
                <p style="color: #375673 !important;"><i class="fa-map-marker fa"></i>
                    Panduranga Enterprises - Import & Trading - NO.84/1, 4th Floor, 3rd Cross, New Timber Yard Layout, Bengaluru<br>
                    Karanataka - 560026
                    <br>
                    
                </p>
                <!-- <p><i class="fa-phone  fa"></i>
                    <a style="color: #375673 !important;font-weight:450">+91-44-42327190</a>
                </p> -->
                <p><i class="fa fa-mobile"></i>
                    <a style="color: #375673 !important;font-weight:450">8310065761</a>
                </p>
                <p><i class="fa-globe  fa"></i>
                    <a style="color: #375673 !important;font-weight:450"  target="_blank">deera2021@gmail.com</a>
                </p>
            </div>
            <!-- <div class="footer-col contact-section address" *ngIf = "config.title == 'AvonSeals'" >
                <h4 class="sm-bold" style="color: #212529;">CONTACT US</h4>
                <p style="color: #375673 !important;"><i class="fa-map-marker fa"></i>
                    235, 9th Street, Sidco Industrial Estate, Ambattur<br>
                    Chennai - 600 098
                    <br>
                    
                </p> -->
                <!-- <p><i class="fa-phone  fa"></i>
                    <a style="color: #375673 !important;font-weight:450">+91-44-42327190</a>
                </p> -->
                <!-- <p><i class="fa fa-mobile"></i>
                    <a style="color: #375673 !important;font-weight:450">+91 9790955409 / 044 42017070</a>
                </p>
                <p><i class="fa-globe  fa"></i>
                    <a style="color: #375673 !important;font-weight:450"  target="_blank">www.AvonSeals.in</a>
                </p>
            </div> -->
           
        </div>
    </div>
</footer>
<footer>
    
    <div class="footer-row-two" style="background-color: #020a12;;">
        <div class="footer-social-icons" style="display: flex;">
            <ul class="social-icons" *ngIf="config.title !== 'Deera' && config.title !== 'AvonSeals' && config.title !== 'BestGifts'">
                <li>
                    <a href="#">
                        <i class="fa fa-twitter"></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="fa fa-facebook"></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="fa fa-linkedin"></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="fa fa-google-plus" aria-hidden="true"></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="fa fa-pinterest" aria-hidden="true"></i>
                    </a>
                </li>

            </ul>
            <div class="footer-col" *ngIf = "config.title == 'AvonSeals'">
            <ul class="social-icons1" style="height: 50px; display: flex; justify-content:flex-start;" *ngIf="config.title === 'AvonSeals'">
                <li>
                <p style="font-size: 12px; color: aliceblue ;"><i style="color: #ff1f1f !important;" class="fa-map-marker fa"></i>
                    Avon Seals Private Limited, 11th street , G-2, Ambattur Industrial Estate, Chennai- 600 058,
                    Tamil Nadu, India</p>
            </li>
            </ul>
        </div>
        <div class="footer-col" *ngIf = "config.title == 'AvonSeals'">
            <ul class="social-icons1" style="height: 50px; display: flex; justify-content:flex-start;" *ngIf="config.title === 'AvonSeals'">
           <li>
                <p style="font-size: 12px; color: aliceblue ;"><i style="color: #ff1f1f !important;" class="fa fa-phone"></i>+91 44 42254995</p>
            </li>
        </ul>
    </div>
    <div class="footer-col" *ngIf = "config.title == 'AvonSeals'">
        <ul class="social-icons1" style="height: 50px; display: flex; justify-content:flex-start;" *ngIf="config.title === 'AvonSeals'">
               <li>
                <p style="font-size: 12px; color:aliceblue ;"><i style="color: #ff1f1f !important;" class="fa fa-envelope"></i>sales@avonseals.com</p>
            </li>
        </ul>
    </div>
    <div class="footer-col1" *ngIf = "config.title == 'AvonSeals'">
        <ul class="social-icons1" style="height: 50px; display: flex; justify-content:flex-start;" *ngIf="config.title === 'AvonSeals'">
                <li>
                    <a  href="#">
                        <i style="color: #ff1f1f !important;" class="fa fa-facebook"></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i style="color: #ff1f1f !important;" class="fa fa-twitter"></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i style="color: #ff1f1f !important;" class="fa fa-pinterest" aria-hidden="true"></i>
                    </a>
                </li>
                <li style="cursor: pointer;">
                    <a  href="#">
                        <i style="color: #ff1f1f !important;" class="fa fa-instagram"></i>
                    </a>
                </li>

            </ul>
        </div>
        <div  *ngIf="config.title == 'BestGifts'" >test</div>
          
        </div>
        <hr style="border: 1px solid white;">
    </div>
  

</footer>
<footer>
    
    <div class="footer-row-three" style="background-color: #020a12;">
        <div class="s-icons">
        <ul class="social-icons" style="display: flex; justify-content: flex-start;" *ngIf="config.title === 'AvonSeals'">
<li>
    <span style="margin-right: 5px;"><img src="../../../../../assets/images/avonseals/visa1.png" alt=""></span>
</li>

<li>
    <span style="margin-right: 5px;"> <img src="../../../../../assets/images/avonseals/master1.png" alt=""></span>
</li>

<li>
    <span style="margin-right: 5px;"> <img src="../../../../../assets/images/avonseals/amex1.png" alt=""></span>
</li>

<li>
    <span style="margin-right: 5px;"> <img src="../../../../../assets/images/avonseals/paypal1.png" alt=""></span>
</li>
<li>
   <span style="margin-right: 5px;"> <img src="../../../../../assets/images/avonseals/discover1.png" alt=""></span>
</li>
<p style="font-size: 10px; text-align:end; color: aliceblue;">© 2023,avonseals Powered by ECBEE</p>
</ul>
</div>
</div>
</footer>


<footer *ngIf="config.title !== 'AvonSeals'">
    
    <div class="footer-row-two">
        <div  *ngIf="config.title == 'BestGifts'" >test</div>
        <div class="s-icons">
            <ul class="social-icons">
                <li>
                    <a href="#">
                        <i class="fa fa-facebook"></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="fa fa-twitter"></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="fa fa-linkedin"></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="fa fa-google-plus" aria-hidden="true"></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="fa fa-pinterest" aria-hidden="true"></i>
                    </a>
                </li>

            </ul>
        </div>
        <img src="../../../../../assets/images/powered_by.png" class="powered_by">
        <div *ngIf="copyRightText && copyRightText !== ''">
            <div class="copy">
                © {{copyRightText}}
            </div>
        </div>
    </div>
</footer>